@font-face {
  font-family: 'ZenKakuGothicNew';
  src: local('ZenKakuGothicNew'), url('./ZenKakuGothicNew-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'ZenKakuGothicNew';
  src: local('ZenKakuGothicNew'), url('./ZenKakuGothicNew-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'ZenKakuGothicNew';
  src: local('ZenKakuGothicNew'), url('./ZenKakuGothicNew-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'ZenKakuGothicNew';
  src: local('ZenKakuGothicNew'), url('./ZenKakuGothicNew-Black.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'ZenKakuGothicNew';
  src: local('ZenKakuGothicNew'), url('./ZenKakuGothicNew-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
